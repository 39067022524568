<template>
  <main id="content" class="programs">
    <router-view
      ref="childView"
      :accApplyPgmMngList="accApplyPgmMngList"
    />
    <!-- 공모 지원하기 Floating Button -->
    <!-- 공모 지원하기 APPLY 페이지로 이동 -->
    <!-- <div
      v-if="isApplicable"
      ref="accApplyFloatingButton"
      class="floating-wrap apply"
      :class="{ on: doShowAccApplyFloatingButton, scrolling: isScrolling }"
    >
      <div v-if="mobiledHackerton"
           @click="noDisplay"
           tag="button"
           class="btn-floating">
        <span class="text">{{$t('content.programs.Application.btn.txt01')}}</span>
      </div>
      <router-link v-else-if="!isApplyCompleted && accApplyPgmMngList[0].accKind === 'AC108'"
                   :to="{ name: 'ProgramsApplyStep1',
        params: {
          accPgmMngNoString: ''+accApplyPgmMngList[0].accPgmMngNo
        } }"
                   tag="button"
                   class="btn-floating"
      ><span class="text">{{$t('content.programs.Application.btn.txt01')}}</span></router-link>
      <router-link v-else-if="!isApplyCompleted && accApplyPgmMngList[0].accKind !== 'AC108'"
                   :to="{ name: 'ProgramsApplication' }"
                   tag="button"
                   class="btn-floating"
      ><span class="text">{{$t('content.programs.Application.btn.txt01')}}</span></router-link>
      <router-link v-else
                   :to="{ name: 'ProgramsMyApplyList',
        params: {
          accPgmMngNoString: ''+accApplyPgmMngList[0].accPgmMngNo
        } }"
                   tag="button"
                   class="btn-floating"
      ><span class="text">{{$t('content.programs.Application.btn.txt01')}}</span></router-link>
    </div> -->
    <!-- // 공모 지원하기 Floating Button -->
  </main>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { gsap } from '@/common/motions';
import MobileDetect from 'mobile-detect';
import debounce from 'lodash/debounce';
import ProgramsApplicationService from './application/service/ProgramsApplicationService';

let lastKnownWindowScrollY = 0;
let ticking = false;

/**
 * Programs
 */
export default {
  name: 'MobilePrograms',
  mixins: [
    mixinHelperUtils,
  ],
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      /**
       * Acc지원프로그램 관리 목록
       * @type {import('./application/dto/AccApplyPgmMngDetailDto').default[]}
       */
      accApplyPgmMngList: undefined,

      /**
       * 공모 지원하기 Floating Button 표시 여부
       * @type {boolean}
       */
      doShowAccApplyFloatingButton: true,

      /**
       * 지원완료 여부
       * @type {boolean}
       */
      isApplyCompleted: false,
      mobiledHackerton: true,
      hackerton: false,
      debounce: {
        moStopScroll: debounce(this.moStopScroll, 50),
      },
      isScrolling: false,
    };
  },

  computed: {
    /**
     * 공모 지원하기 가능 여부
     * @type {boolean}
     */
    isApplicable() {
      const { path } = this.$route;
      // 지원가능한 공모 목록이 있는 경우에만 공모 지원하기 가능
      // 그리고 공모 지원하기 화면에서는 제외
      // 조건 추가 acc가 AC108이면서 url apply 이거나 AC108아니며 url programs 일 경우 가능
      if (this.accApplyPgmMngList) {
        if (this.accApplyPgmMngList.length > 0) {
          if (((/\/programs\/programs/.test(path)) && this.accApplyPgmMngList[0].accName.indexOf('해커톤') !== -1)) {
            return false;
          }
        }
      }

      return this.accApplyPgmMngList
        && this.accApplyPgmMngList.length > 0
        && (
          ((path.match(/\/apply/) || path.match(/\/applyweb/)) && this.accApplyPgmMngList[0].accKind === 'AC108')
          || ((path.match(/\/programs/) && this.accApplyPgmMngList[0].accKind !== 'AC108')
          )
        )
        && !(/\/programs\/application/.test(path));
    },

    /**
     * K - STARTUP MOBILE 구분
     * @type {boolean}
     */
    isMobile() {
      const md = new MobileDetect(window.navigator.userAgent);
      return md.mobile() === null;
    },
  },

  watch: {
    $route() {
      // 공모 지원하기 버튼 숨기기
      this.doShowAccApplyFloatingButton = false;

      if (this.isApplicable) {
        this.showAccApplyFloatingButton();
      }
    },

    /**
     * 공모 지원하기 가능 여부 변경시
     */
    isApplicable(newVal, oldVal) {
      if (newVal && !oldVal) { // 가능으로 변경시
        this.showAccApplyFloatingButton();
      }
    },
  },

  async created() {
    this.programsApplicationService = new ProgramsApplicationService(this.portalApiClient);
    const md = new MobileDetect(window.navigator.userAgent);
    this.accApplyPgmMngList = await this.programsApplicationService.getAccApplyPgmMngList();
    // 로그인 상태이면 지원완료 여부 조회

    if (window.location.pathname === '/programs/hackathon') {
      // this.isPC = true;
      this.hackerton = true;
      this.accApplyPgmMngList = await this.programsApplicationService.getAccApplySpecialPgmMng();
    } else {
      // this.isPC = md.mobile() === null;
      this.accApplyPgmMngList = await this.programsApplicationService.getAccApplyPgmMngList();
    }

    const { userInfo } = this.$store.state;
    if (userInfo && this.accApplyPgmMngList.length > 0) {
      await this.programsApplicationService.getTempAccApply(this.accApplyPgmMngList[0].accPgmMngNo)
        .then(({ status }) => {
          this.isApplyCompleted = (status === 737); // 737: 이미 지원한 내역이 있습니다.
        });
    }

    // scroll event : https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
    if (md.mobile()) {
      window.addEventListener('scroll', this.moScroll);
    } else {
      window.addEventListener('scroll', this.onWindowScroll);
    }
  },

  destroyed() {
    // scroll event : https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
    window.removeEventListener('scroll', this.moScroll);
  },

  methods: {
    moStopScroll() {
      setTimeout(() => {
        this.isScrolling = false;
      }, 500);
    },
    moScroll() {
      this.isScrolling = true;
      this.debounce.moStopScroll();
    },
    /**
     * 공모 지원하기 버튼 표시하기
     */
    showAccApplyFloatingButton() {
      this.$nextTick(() => {
        const el = this.$refs.accApplyFloatingButton;

        // 공모 지원하기 버튼 커버 이후 나타나기
        const { cover } = this.$refs.childView.$refs;
        if (cover) {
          const offsetY = 80;

          gsap.fromTo(
            el,
            {
              autoAlpha: 0,
              y: -30,
            },
            {
              autoAlpha: 1,
              y: () => window.scrollY || window.pageYOffset || 0, // ie11
              scrollTrigger: {
                // markers: true,
                trigger: '.title-section',
                endTrigger: 'footer',
                start: `top 1%+=${offsetY}`,
                once: true,
                onEnter: () => {
                  this.doShowAccApplyFloatingButton = true;
                },
              },
              duration: 1.4,
              ease: 'Back.easeOut',
            },
          );
        } else {
          this.doShowAccApplyFloatingButton = true;

          gsap.fromTo(
            el,
            {
              autoAlpha: 0,
              y: -30,
            },
            {
              autoAlpha: 1,
              y: () => window.scrollY || window.pageYOffset || 0, // ie11
              duration: 1.4,
              delay: 2,
              ease: 'Back.easeOut',
            },
          );
        }
      });
    },
    noDisplay() {
      this.showAlert('공모 지원은 PC 환경에서만 지원 가능합니다. PC 화면에서 진행해 주시기 바랍니다.');
    },
    /**
     * window 스크롤 이벤트 처리
     * @see window.scrollY : https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
     *      * ie11 : https://github.com/zwug/react-full-page/pull/31/commits/3383968609c9437cf1d11d9ef24aaa51eb2fad2b
     * @see ease : https://greensock.com/docs/v3/Eases
     */
    onWindowScroll() {
      lastKnownWindowScrollY = window.scrollY || window.pageYOffset || 0; // ie11

      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (this.isApplicable && this.doShowAccApplyFloatingButton) {
            // 공모 지원하기 버튼 커버로는 이동하지 않도록
            const { cover } = this.$refs.childView.$refs;
            if (cover && lastKnownWindowScrollY <= (cover.offsetHeight - 50)) {
              ticking = false;
              return;
            }

            const el = this.$refs.accApplyFloatingButton;
            gsap.to(
              el,
              {
                y: lastKnownWindowScrollY,
                duration: 0.5,
                ease: 'power1.inOut',
              },
            );
          }

          ticking = false;
        });

        ticking = true;
      }
    },
  },
};
</script>
<style>
.floating-wrap {position:absolute; display:none; top:8rem; right:1.4rem; z-index:100;}
.floating-wrap.on {display:block;}
.btn-floating {position:relative; display:inline-block; min-width: 5.8rem;
  height:5.8rem; padding-left:4.8rem; box-sizing: border-box;
  font-size: 1.6rem; font-weight: 700; line-height: 1.29; letter-spacing: normal; text-align: center;
  color:#fff; border-radius:2.9rem; background:#ff5013; z-index:10; cursor:pointer;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.22); transition:.7s;}
.btn-floating .text { display: inline-block; margin-right: 2.1rem; line-height: 5.8rem;}
.btn-floating:before {content:''; display:inline-block; width:2.8rem; height:2.8rem; vertical-align: middle;
  position: absolute; left: 1.5rem; top: 50%; transform: translateY(-50%);
  background:url('../../assets/images/icons/icon-floating.png') no-repeat center center/cover;}
.btn-floating:hover {background:#BD3B0E;}
.btn-floating > span {word-break: keep-all;}
.btn-next:hover, .btn-prev:hover {background:rgba(255,255,255,.6);}
.floating-wrap.apply {align-items: center; justify-content: center; top:80px !important;
 position: fixed !important; transform: translate(0, 0) !important;}
.floating-wrap.apply.on {display:flex;}
.floating-wrap.apply .btn-floating {background:#3190d6; padding-left:5.8rem;}
.floating-wrap.apply .btn-floating:hover {background:#246a9e;}
.floating-wrap.apply .btn-floating:before {background-image:url('../../assets/images/icons/btn-apply.png');}
.floating-wrap.apply .rotate-circle {position:absolute; top:0; left:0; transform: scale(1.35);}
.floating-wrap.apply .rotate-circle .caption {font-size:8px; font-weight:300; letter-spacing:-0.2px;
  fill:#3287c6; overflow:hidden; transform-origin: 50% 50%; animation: rotate 10s linear infinite;}
.floating-wrap.apply:hover .rotate-circle .caption {fill:#246a9e;}
.floating-wrap.scrolling .btn-floating {width: 5.8rem; overflow: hidden;}
.floating-wrap.scrolling .btn-floating .text{ opacity: 0;}
</style>
